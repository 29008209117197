.admin {
  width: 100%;
}

.admin main.page_wrapper {
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: var(--theme-dark-blue);
}

.admin main.page_wrapper header {
  width: 100%;
  background-color: none;
  padding-top: 30px;
}

.admin main.page_wrapper header h1 {
  display: block;
  text-align: center;
  width: 100%;
  color: #fff;
}

.admin main.page_wrapper .admin_pools_wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper {
  padding: 15px;
  border-radius: 20px;
  background-color: #fff;
  margin: 20px;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool {
  padding: 15px;
  width: 320px;
  background-color: #fff;
  border: 2px solid var(--theme-yellow);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .top .pr_name {
  font-size: 12px;
  font-weight: bold;
  margin-right: 30px;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .top .pr_status {
  color: white;
  padding: 3px 16px;
  border-radius: 20px;
  font-size: 14px;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .mid {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .mid .btn {
  padding: 5px 8px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 12px;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .mid .x {
  background-color: var(--theme-yellow);
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .mid .y {
  background-color: #EC1B6C;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .mid .x,
.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .mid .y {
  margin-right: 10px;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .mid .z {
  background-color: #000;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .btm {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .btm .btn_2 {
  padding: 5px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .btm .view {
  background-color: #627EEA;
  color: white;
}

.admin main.page_wrapper .admin_pools_wrapper .wrapper .admin_pool .btm .cancel {
  margin-left: 15px;
  color: #627EEA;
  border: 2px solid var(--theme-yellow);
}
