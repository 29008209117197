.my_locks_list {
  width: 100%;
}

.my_locks_list main.page_wrapper {
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: var(--theme-dark-blue);
}

.my_locks_list main.page_wrapper .react_link {
  width: fit-content;
  margin: 0 auto;
}

.my_locks_list main.page_wrapper .locks_wrapper {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.my_locks_list main.page_wrapper .locks_wrapper .nl {
  display: block;
  width: 100%;
  color: white;
  font-size: 30px;
  text-align: center;
}

.my_locks_list main.page_wrapper .locks_wrapper .lock_card {
  min-width: 300px;
  padding: 30px 20px;
  background-color: white;
  border-radius: 15px;
  margin: 20px;
}

.my_locks_list main.page_wrapper .locks_wrapper .lock_card .title {
  display: block;
  width: 100%;
  font-weight: bold;
  text-align: left;
  font-size: 20px;
  margin-bottom: 20px;
}

.my_locks_list main.page_wrapper .locks_wrapper .lock_card hr.rule {
  width: 100%;
  background-color: #6a6a6a;
  background-color: #6a6a6a;
  margin-bottom: 20px;
}

.my_locks_list main.page_wrapper .locks_wrapper .lock_card .card_row {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.my_locks_list main.page_wrapper .locks_wrapper .lock_card .card_row span {
  display: block;
  word-wrap: break-word;
}

.my_locks_list main.page_wrapper .locks_wrapper .lock_card .card_row .l {
  text-align: left;
  width: 30%;
}

.my_locks_list main.page_wrapper .locks_wrapper .lock_card .card_row .r {
  text-align: right;
  width: 70%;
}

.my_locks_list main.page_wrapper .locks_wrapper .lock_card .card_row .adr {
  font-weight: bold;
  color: var(--theme-yellow);
}

.my_locks_list main.page_wrapper .locks_wrapper .lock_card .view {
  padding: 10px 40px;
  cursor: pointer;
  background-color: var(--theme-yellow);
  width: fit-content;
  margin: 0 auto;
  border-radius: 10px;
}
