.navbar {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px 0px 20px;
  background-color: #fff;
}

@media (max-width: 1100px) {
  .navbar {
    background-color: var(--theme-dark-blue);
    padding-left: 5px;
  }
}

.navbar .logo {
  width: 150px;
  height: 60%;
}

.navbar .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.navbar .connect_btn_wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .connect_btn_wrapper .btn {
  padding: 10px 50px;
  background-color: var(--theme-yellow);
  color: white;
  border-radius: 12px;
  cursor: pointer;
  margin: auto 0;
}

@media (max-width: 800px) {
  .navbar .connect_btn_wrapper .btn {
    padding: 6px 20px;
  }
}

.navbar .navlinks_or_menuBtn {
  height: 55%;
}

.navbar .navlinks_or_menuBtn ul {
  width: fit-content;
  height: 65%;
  display: flex;
  height: 100%;
  list-style: none;
  justify-content: flex-start;
}

@media (max-width: 1100px) {
  .navbar .navlinks_or_menuBtn ul {
    display: none;
  }
}

.navbar .navlinks_or_menuBtn ul li {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 20px;
}

.navbar .navlinks_or_menuBtn ul li .react_link {
  padding: 0px 10px;
  border-radius: 4px;
}

@media (max-width: 1350px) {
  .navbar .navlinks_or_menuBtn ul li .react_link {
    padding: 0px 5px;
  }
}

.navbar .navlinks_or_menuBtn ul li .react_link .items {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.navbar .navlinks_or_menuBtn ul li .react_link .items .icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.navbar .navlinks_or_menuBtn ul li .react_link .items .icon img {
  width: 100%;
  height: 100%;
}

.navbar .navlinks_or_menuBtn ul li .react_link .items span.text {
  font-size: 17px;
}

@media (max-width: 1350px) {
  .navbar .navlinks_or_menuBtn ul li .react_link .items span.text {
    font-size: 12px;
  }
}

.navbar .navlinks_or_menuBtn ul li .react_link:hover {
  border: 2px solid black;
}

.navbar .navlinks_or_menuBtn .menu {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1100px) {
  .navbar .navlinks_or_menuBtn .menu {
    display: none;
  }
}

.navbar .navlinks_or_menuBtn .menu .menu_icon {
  height: 60%;
  width: 45px;
}

.navbar .navlinks_or_menuBtn .menu .menu_icon img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.desktop {
  display: block;
}

@media (min-width: 1100px) {
  .desktop {
    display: none !important;
  }
}
