.launchpage {
  width: 100%;
  position: relative;
}

.launchpage .edit_modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  z-index: 99;
}

.launchpage .edit_modal .edit_form {
  max-width: 400px;
  min-width: 350px;
  max-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  padding: 20px;
}

.launchpage .edit_modal .edit_form .form_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.launchpage .edit_modal .edit_form .form_title .title {
  padding: 7px 15px;
  background-color: var(--theme-yellow);
  color: white;
  font-size: 14px;
}

.launchpage .edit_modal .edit_form .form_title .close {
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  text-align: center;
}

.launchpage .edit_modal .edit_form .modal_input_group {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.launchpage .edit_modal .edit_form .modal_input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.launchpage .edit_modal .edit_form .modal_input_group .modal_form_input {
  width: 100%;
  border: none;
  background: none;
  border: 2px solid var(--theme-yellow);
  border-radius: 10px;
  height: 22px;
  outline-color: var(--theme-yellow);
  padding-left: 12px;
  font-size: 10px;
}

.launchpage .edit_modal .edit_form button {
  border: none;
  background-color: var(--theme-yellow);
  color: white;
  border-radius: 6px;
  cursor: pointer;
  padding: 7px 25px;
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 5px;
}

.launchpage main.page_wrapper {
  background-color: var(--theme-dark-blue);
  min-height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.launchpage main.page_wrapper .top_section {
  width: 100%;
  margin-bottom: 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3d3d3b;
  padding-bottom: 30px;
}

@media (max-width: 1250px) {
  .launchpage main.page_wrapper .top_section {
    height: 570px;
    align-items: flex-start;
    max-width: 95%;
  }
}

.launchpage main.page_wrapper .top_section .logo_and_name {
  margin-left: 30px;
  height: 250px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
}

@media (max-width: 1000px) {
  .launchpage main.page_wrapper .top_section .logo_and_name {
    margin-left: 10px;
    width: 250px;
  }
}

.launchpage main.page_wrapper .top_section .logo_and_name .logo_img {
  width: 80%;
  height: 70%;
  margin-bottom: 30px;
}

.launchpage main.page_wrapper .top_section .logo_and_name .logo_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1000px) {
  .launchpage main.page_wrapper .top_section .logo_and_name .logo_img {
    width: 55%;
    height: 60%;
    margin-bottom: 5px;
  }
}

.launchpage main.page_wrapper .top_section .logo_and_name .project_name {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: var(--theme-yellow);
}

@media (max-width: 1000px) {
  .launchpage main.page_wrapper .top_section .logo_and_name .project_name {
    font-size: 15px;
  }
}

.launchpage main.page_wrapper .top_section .description {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}

@media (max-width: 1250px) {
  .launchpage main.page_wrapper .top_section .description {
    top: 300px;
  }
}

.launchpage main.page_wrapper .top_section .description span {
  color: white;
  word-wrap: break-word;
  max-width: 600px;
}

@media (max-width: 1250px) {
  .launchpage main.page_wrapper .top_section .description span {
    max-width: 90%;
  }
}

.launchpage main.page_wrapper .top_section .buttons {
  width: 300px;
  height: 300px;
  margin-right: 20px;
  padding-top: 40px;
}

.launchpage main.page_wrapper .top_section .buttons .top {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.launchpage main.page_wrapper .top_section .buttons .top .button {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 20px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 15px;
  color: white;
  background-color: #1ACC78 !important;
}

@media (max-width: 1000px) {
  .launchpage main.page_wrapper .top_section .buttons .top .button {
    padding: 5px 10px;
    font-size: 12px;
  }
}

.launchpage main.page_wrapper .top_section .buttons .top .button .circ {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  margin-right: 7px;
}

@media (max-width: 1000px) {
  .launchpage main.page_wrapper .top_section .buttons .top .button .circ {
    width: 10px;
    height: 10px;
  }
}

.launchpage main.page_wrapper .top_section .buttons .bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.launchpage main.page_wrapper .top_section .buttons .bottom .button {
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 8px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  margin-left: 20px;
}

@media (max-width: 1000px) {
  .launchpage main.page_wrapper .top_section .buttons .bottom .button {
    padding: 5px 10px;
    font-size: 12px;
    margin-left: 10px;
  }
}

.launchpage main.page_wrapper .top_section .buttons .bottom .a {
  background-color: #F3BA2F;
}

.launchpage main.page_wrapper .top_section .buttons .bottom .b {
  background-color: #627EEA;
}

.launchpage main.page_wrapper .top_section .buttons .bottom .c {
  background-color: #EC1B6C;
}

.launchpage main.page_wrapper .section_2 {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 80px;
}

@media (max-width: 1000px) {
  .launchpage main.page_wrapper .section_2 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 70px;
  }
}

.launchpage main.page_wrapper .section_2 .card {
  border-radius: 0px;
}

.launchpage main.page_wrapper .section_2 .project_details {
  width: 45%;
  padding: 30px 0px;
}

@media (max-width: 1000px) {
  .launchpage main.page_wrapper .section_2 .project_details {
    width: 100%;
  }
}

.launchpage main.page_wrapper .section_2 .project_details .table {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.launchpage main.page_wrapper .section_2 .project_details .table hr.rule {
  width: 100%;
  background-color: #4b4b4b;
  border: 1px solid #4b4b4b;
  margin-bottom: 10px;
}

.launchpage main.page_wrapper .section_2 .project_details .table .card_row {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
}

.launchpage main.page_wrapper .section_2 .project_details .table .card_row span {
  display: block;
  word-wrap: break-word;
}

.launchpage main.page_wrapper .section_2 .project_details .table .card_row .l {
  text-align: left;
  width: 30%;
  color: #fff;
}

.launchpage main.page_wrapper .section_2 .project_details .table .card_row .r {
  text-align: right;
  width: 70%;
  color: var(--theme-yellow);
}

.launchpage main.page_wrapper .section_2 .project_details .table .card_row .listing {
  cursor: pointer;
  color: var(--theme-yellow);
}

.launchpage main.page_wrapper .section_2 .project_details .table .card_row .address {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.launchpage main.page_wrapper .section_2 .project_details .table .card_row .address .address_span {
  display: block;
  width: 100%;
  color: var(--theme-yellow);
  word-wrap: break-word;
  margin-bottom: 20px;
}

.launchpage main.page_wrapper .section_2 .project_details .table .card_row .address .warning {
  display: block;
  width: 100%;
  color: red;
  font-weight: bold;
  word-wrap: break-word;
}

.launchpage main.page_wrapper .section_2 .project_details .ensure {
  background-color: #000;
  color: #fff;
  padding: 10px 30px;
  margin-bottom: 10px;
  text-align: center;
}

@media (max-width: 1000px) {
  .launchpage main.page_wrapper .section_2 .project_details .ensure {
    max-width: 80%;
    font-size: 14px;
  }
}

.launchpage main.page_wrapper .section_2 .right_card .table {
  margin-top: 30px;
  width: 85% !important;
}

.launchpage main.page_wrapper .section_2 .right_card .card_row {
  margin-bottom: 20px !important;
}

.launchpage main.page_wrapper .section_2 .right_card .rule {
  margin-bottom: 15px !important;
}

.launchpage main.page_wrapper .section_2 .right_card .l {
  width: 60% !important;
}

.launchpage main.page_wrapper .section_2 .right_card .r {
  width: 40%;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .end {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .timer {
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .timer div {
  padding: 7px;
  border-radius: 6px;
  background-color: var(--theme-yellow);
  color: white;
  margin-right: 10px;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .fill_bar {
  width: 100%;
  margin-bottom: 15px;
  background-color: #FBEDBD;
  border-radius: 20px;
  height: 25px;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .fill_bar .filler {
  border-radius: inherit;
  height: inherit;
  width: 30%;
  background-color: var(--theme-yellow);
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .filler_values {
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .input_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .input_section span.amount {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-bottom: 15px;
  color: #fff;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .input_section .input_wrapper {
  width: 100%;
  height: 30px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .input_section .input_wrapper input {
  width: 85%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0px 15px;
  color: var(--theme-dark-blue);
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .input_section .input_wrapper .max {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .input_section .input_wrapper .max span {
  color: var(--theme-yellow);
  font-weight: bold;
  cursor: pointer;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .btns div {
  padding: 12px 20px;
  border-radius: 6px;
  background-color: var(--theme-yellow);
  color: white;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .launchpage main.page_wrapper .section_2 .right_card .creator_section .btns div {
    padding: 8px 14px;
    font-size: 14px;
  }
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth {
  margin-top: 30px;
  width: 100%;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .title {
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 8px 0px;
  font-weight: bold;
  margin-bottom: 40px;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .input_group_3 {
  margin: 40px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .input_group_3 .group_3_label {
  width: 100%;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .input_group_3 .group_3_label span.mt {
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .input_group_3 .group_3_label .whitelist_select {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .input_group_3 .group_3_label .whitelist_select .group {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  margin-right: 50px;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .input_group_3 .group_3_label .whitelist_select .group .outer {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .input_group_3 .group_3_label .whitelist_select .group .outer .inner {
  width: 8px;
  height: 8px;
  background-color: var(--theme-yellow);
  border-radius: 50%;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .input_group_3 .group_3_label .whitelist_select .group .group_text {
  color: white;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .input_group_3 .group_3_label span.required_label {
  display: block;
  width: 100%;
  text-align: left;
  color: var(--theme-yellow);
  font-size: 17px;
  margin-bottom: 0px;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .contributions {
  width: 100%;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .contributions .card_row {
  width: 100%;
  display: flex;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .contributions .l {
  color: #fff;
  width: 30%;
  display: block;
}

.launchpage main.page_wrapper .section_2 .right_card .creator_section .creator_section_auth .contributions .r {
  color: var(--theme-yellow);
  width: 70%;
  text-align: right;
  display: block;
}
