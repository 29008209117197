.card {
  width: 300px;
  height: fit-content;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 40px;
}

.card .rings_container {
  margin: 50px 0px;
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card .rings_container .outer_ring {
  width: inherit;
  height: inherit;
  border: 10px solid #D9D9D9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card .rings_container .outer_ring .circle {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card .rings_container .outer_ring .circle span {
  font-weight: bold;
  font-size: 30px;
}

.card .rings_container .inner_ring {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card .rings_container .yellowRing::before {
  content: "";
  width: 90%;
  height: 90%;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  border: 10px solid;
  border-color: var(--theme-yellow) transparent transparent transparent;
  animation: rotateArc 3.5s infinite;
}

.card .rings_container .blueRing::before {
  content: "";
  width: 90%;
  height: 90%;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  border: 10px solid;
  border-color: var(--theme-dark-blue) transparent transparent transparent;
  animation: rotateArc 3.5s infinite;
}

.card span.caption {
  margin-top: -20px;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 35px;
  font-size: 20px;
}

@keyframes rotateArc {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
