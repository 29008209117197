.home {
  width: 100%;
}

.home main {
  width: 100%;
  margin-bottom: 45px;
}

.home main .hero_section {
  width: 100%;
  height: 50vh;
  background-color: var(--theme-dark-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.home main .home_btns {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.home main .home_btns .btn {
  width: fit-content;
  border-radius: 25px;
}

.home main .home_btns .react_link {
  padding: 12px 50px;
}

.home main .home_btns .create {
  background-color: var(--theme-dark-blue);
  color: white;
  margin-right: 50px;
}

@media (max-width: 1000px) {
  .home main .home_btns .create {
    margin-right: 25px;
  }
}

.home main .home_btns .explore {
  border: 2px solid var(--theme-dark-blue);
  color: var(--theme-dark-blue);
}

.home .homepage_info_section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
