.sidebar {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(35, 35, 35, 0.69);
}

.sidebar .wrapper {
  width: 250px;
  height: 100vh;
  background-color: var(--theme-dark-blue);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sidebar .wrapper .close_sidebar {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  color: white;
  font-size: 25px;
  font-weight: bold;
}

.sidebar .wrapper .close_sidebar span {
  padding: 20px;
  cursor: pointer;
}

.sidebar .wrapper .logo {
  margin: 20px 0px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 25px;
}

.sidebar .links {
  width: 100%;
}

.sidebar .links ul {
  width: 100%;
  list-style: none;
}

.sidebar .links ul li {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 25px;
  cursor: pointer;
  margin-bottom: 15px;
}

.sidebar .links ul li .icon {
  margin-right: 10px;
}

.sidebar .links ul li .icon img {
  width: 20px;
  fill: inherit;
  height: 20px;
}

.sidebar .links ul li .link_text {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.sidebar .links ul li:hover {
  background-color: white;
}

.sidebar .links ul li:hover .link_text {
  color: black;
}
