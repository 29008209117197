.page_1 {
  width: 100%;
}

.page_1 main.page_main {
  padding: 30px;
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: var(--theme-dark-blue);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.page_1 main.page_main .items_wrapper {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.page_1 main.page_main .items_wrapper span.required_label {
  display: block;
  width: 100%;
  text-align: left;
  color: var(--theme-yellow);
  font-size: 17px;
  margin-bottom: 30px;
}

.page_1 main.page_main .items_wrapper .input_group_1 {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.page_1 main.page_main .items_wrapper .input_group_1 .group_1_label {
  margin-bottom: 10px;
  width: 100%;
}

.page_1 main.page_main .items_wrapper .input_group_1 .group_1_label span.mt {
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
}

.page_1 main.page_main .items_wrapper .input_group_1 .group_1_label span.ast {
  font-weight: bold;
  color: var(--theme-yellow);
  font-size: 18px;
}

.page_1 main.page_main .items_wrapper .input_group_1 .group_1_input {
  width: 100%;
  margin-bottom: 10px;
}

.page_1 main.page_main .items_wrapper .input_group_1 .group_1_input input[type="text"],
.page_1 main.page_main .items_wrapper .input_group_1 .group_1_input [type="number"],
.page_1 main.page_main .items_wrapper .input_group_1 .group_1_input [type="date"] {
  border: none;
  background-color: white;
  width: 100%;
  height: 35px;
  padding-left: 10px;
  color: var(--theme-dark-blue);
  outline: none;
}

.page_1 main.page_main .items_wrapper .input_group_1 span.group_1_note {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  color: var(--theme-yellow);
}

.page_1 main.page_main .items_wrapper .token_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.page_1 main.page_main .items_wrapper .token_info .ti_row {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.page_1 main.page_main .items_wrapper .token_info .ti_row span.data,
.page_1 main.page_main .items_wrapper .token_info .ti_row span.value {
  display: block;
  height: 30px;
  width: 100%;
  text-align: left;
  font-weight: bold;
}

.page_1 main.page_main .items_wrapper .token_info .ti_row span.data {
  color: white;
}

.page_1 main.page_main .items_wrapper .token_info .ti_row span.value {
  color: var(--theme-yellow);
}

.page_1 main.page_main .items_wrapper .currency_details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.page_1 main.page_main .items_wrapper .currency_details span {
  display: block;
  width: 100%;
  text-align: left;
}

.page_1 main.page_main .items_wrapper .currency_details span.currency {
  font-weight: bold;
  color: white;
  font-size: 18px;
  margin-bottom: 7px;
}

.page_1 main.page_main .items_wrapper .currency_details span.symbol {
  color: var(--theme-yellow);
}

.page_1 main.page_main .items_wrapper span.notice {
  display: block;
  width: 100%;
  text-align: left;
  color: var(--theme-yellow);
  font-weight: 700;
  margin-bottom: 25px;
}

.page_1 main.page_main .items_wrapper .warning_card {
  width: 100%;
  padding: 10px;
  border: 2px solid #FDEBAD;
  border-radius: 5px;
  background-color: #FDFAE1;
  font-size: 15px;
  margin-bottom: 30px;
}

.page_1 main.page_main .items_wrapper .form_buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_1 main.page_main .items_wrapper .form_buttons button {
  width: fit-content !important;
  border: none;
  padding: 10px 40px;
  color: white;
  border-radius: 10px;
  background-color: var(--theme-yellow);
  cursor: pointer;
}

.page_1 main.page_main .items_wrapper .form_buttons button:hover {
  background-color: #d89d08;
}
