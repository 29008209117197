.my_locks {
  width: 100%;
}

.my_locks main.locks_wrapper {
  width: 100%;
  padding: 30px 20px 100px 20px;
  background-color: var(--theme-dark-blue);
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 1100px) {
  .my_locks main.locks_wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.my_locks main.locks_wrapper .left {
  margin-right: 50px;
}

@media (max-width: 1100px) {
  .my_locks main.locks_wrapper .left {
    width: 95%;
    margin-right: 0px;
    margin-bottom: 50px;
  }
}

.my_locks main.locks_wrapper .lock_card {
  width: 40%;
  padding: 30px 20px;
  background-color: white;
  border-radius: 15px;
}

@media (max-width: 1100px) {
  .my_locks main.locks_wrapper .lock_card {
    width: 95%;
  }
}

.my_locks main.locks_wrapper .lock_card .title {
  display: block;
  width: 100%;
  font-weight: bold;
  text-align: left;
  font-size: 20px;
  margin-bottom: 20px;
}

.my_locks main.locks_wrapper .lock_card hr.rule {
  width: 100%;
  background-color: #6a6a6a;
  background-color: #6a6a6a;
  margin-bottom: 20px;
}

.my_locks main.locks_wrapper .lock_card .card_row {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.my_locks main.locks_wrapper .lock_card .card_row span {
  display: block;
  word-wrap: break-word;
}

.my_locks main.locks_wrapper .lock_card .card_row .l {
  text-align: left;
  width: 30%;
}

.my_locks main.locks_wrapper .lock_card .card_row .r {
  text-align: right;
  width: 70%;
}

.my_locks main.locks_wrapper .lock_card .card_row .adr {
  font-weight: bold;
  color: var(--theme-yellow);
}

.my_locks main.locks_wrapper .lock_card .unlock {
  padding: 10px 40px;
  cursor: pointer;
  background-color: var(--theme-yellow);
  width: fit-content;
  margin: 20px auto;
  border-radius: 10px;
}
