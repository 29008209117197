.page_3 {
  width: 100%;
}

.page_3 main.page_main {
  padding: 30px;
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: var(--theme-dark-blue);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.page_3 .form_buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.page_3 .form_buttons button {
  width: fit-content !important;
  border: none;
  padding: 10px 40px;
  color: white;
  border-radius: 10px;
  background-color: var(--theme-yellow);
  cursor: pointer;
}

.page_3 .form_buttons button:hover {
  background-color: #d89d08;
}

.page_3 .items_wrapper {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 1000px) {
  .page_3 .items_wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.page_3 .items_wrapper .form_section {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 1000px) {
  .page_3 .items_wrapper .form_section {
    width: 100%;
  }
}

.page_3 .items_wrapper .form_section span.required_label {
  display: block;
  width: 100%;
  text-align: left;
  color: var(--theme-yellow);
  font-size: 17px;
  margin-bottom: 30px;
}

.page_3 .items_wrapper .form_section .input_group_2 {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.page_3 .items_wrapper .form_section .input_group_2 .group_2_label {
  margin-bottom: 10px;
  width: 100%;
}

.page_3 .items_wrapper .form_section .input_group_2 .group_2_label span.mt {
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
}

.page_3 .items_wrapper .form_section .input_group_2 .group_2_label span.ast {
  font-weight: bold;
  color: var(--theme-yellow);
  font-size: 18px;
}

.page_3 .items_wrapper .form_section .input_group_2 .group_2_input {
  width: 100%;
  margin-bottom: 10px;
}

.page_3 .items_wrapper .form_section .input_group_2 .group_2_input input[type="text"],
.page_3 .items_wrapper .form_section .input_group_2 .group_2_input [type="number"],
.page_3 .items_wrapper .form_section .input_group_2 .group_2_input [type="date"] {
  border: none;
  background-color: white;
  width: 100%;
  height: 35px;
  padding-left: 10px;
  color: var(--theme-dark-blue);
  outline: none;
}

.page_3 .items_wrapper .form_section .input_group_2 span.group_2_note {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 17px;
  color: var(--theme-yellow);
}

.page_3 .items_wrapper .form_section .input_group_2 span.group_2_note_bold {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 17px;
  color: white;
  font-weight: bold;
}

.page_3 .input_group_2_textarea {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.page_3 .input_group_2_textarea .group_2_label {
  margin-bottom: 10px;
  width: 100%;
}

.page_3 .input_group_2_textarea .group_2_label span.mt {
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
}

.page_3 .input_group_2_textarea .group_2_label span.ast {
  font-weight: bold;
  color: var(--theme-yellow);
  font-size: 18px;
}

.page_3 .input_group_2_textarea .group_3_input {
  width: 40%;
  margin-bottom: 20px;
}

@media (max-width: 1000px) {
  .page_3 .input_group_2_textarea .group_3_input {
    width: 100%;
  }
}

.page_3 .input_group_2_textarea .group_3_input textarea {
  border: none;
  background-color: white;
  width: 100%;
  max-width: 100%;
  height: 170px;
  max-height: 200px;
  padding-left: 10px;
  color: var(--theme-dark-blue);
  outline: none;
}
