.airdrop {
  width: 100%;
}

.airdrop .airdrop_wrapper {
  background-color: var(--theme-dark-blue);
  padding: 40px;
  width: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 1000px) {
  .airdrop .airdrop_wrapper {
    padding: 40px 15px;
  }
}

.airdrop .airdrop_wrapper .badge_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.airdrop .airdrop_wrapper .badge_wrapper .badge {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  border-radius: 12px;
}

@media (max-width: 1000px) {
  .airdrop .airdrop_wrapper .badge_wrapper .badge {
    padding: 7px 15px;
    font-size: 12px;
  }
}

.airdrop .airdrop_wrapper .badge_wrapper .badge img {
  margin-right: 10px;
}

.airdrop .airdrop_wrapper .badge_wrapper .badge span {
  color: var(--theme-dark-blue);
  font-weight: bold;
}

.airdrop .airdrop_wrapper .note_ad {
  display: block;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
}

.airdrop .airdrop_wrapper .section_ins {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--theme-dark-blue);
  padding: 20px;
}

.airdrop .airdrop_wrapper .section_ins span {
  width: 100%;
  display: block;
  text-align: left;
  margin-bottom: 30px;
  color: #fff;
}

.airdrop .airdrop_wrapper .section_ins .t1 {
  font-weight: bold;
}

.airdrop .airdrop_wrapper .section_ins .fee {
  margin-top: 50px;
}

.airdrop .airdrop_wrapper .section_2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 40px;
}

@media (max-width: 1100px) {
  .airdrop .airdrop_wrapper .section_2 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.airdrop .airdrop_wrapper .section_2 .inputs {
  width: 45%;
  margin-right: 50px;
}

@media (max-width: 1100px) {
  .airdrop .airdrop_wrapper .section_2 .inputs {
    width: 100%;
    margin-right: 0;
  }
}

.airdrop .airdrop_wrapper .section_2 .inputs .address_entry {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  background-color: #fff;
  border-radius: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.airdrop .airdrop_wrapper .section_2 .inputs .address_entry span {
  color: #8297ff;
  display: block;
  width: 100%;
  font-weight: bold;
}

.airdrop .airdrop_wrapper .section_2 .inputs .address_entry input {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  color: var(--theme-dark-blue);
  border-radius: inherit;
}

.airdrop .airdrop_wrapper .section_2 .inputs .token_dt {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.airdrop .airdrop_wrapper .section_2 .inputs .token_dt .gp {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}

.airdrop .airdrop_wrapper .section_2 .inputs .token_dt .gp span.l {
  color: #fff;
  margin-right: 10px;
  display: block;
  width: 30%;
}

.airdrop .airdrop_wrapper .section_2 .inputs .token_dt .gp span.r {
  color: var(--theme-yellow);
  font-weight: bold;
  display: block;
  width: 70%;
}

@media (max-width: 1000px) {
  .airdrop .airdrop_wrapper .section_2 .inputs .token_dt .gp span {
    font-size: 12px;
  }
}

.airdrop .airdrop_wrapper .section_2 .inputs .list {
  margin-top: 50px;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.airdrop .airdrop_wrapper .section_2 .inputs .list span {
  display: block;
  width: 100%;
  color: var(--theme-dark-blue);
  font-weight: bold;
  margin-bottom: 30px;
}

.airdrop .airdrop_wrapper .section_2 .inputs .list textarea {
  padding: 10px;
  height: 200px;
  border: 2px solid var(--theme-dark-blue);
  border-radius: 10px;
  min-height: 200px;
  max-width: 100%;
  min-width: 100%;
  max-height: 300px;
}

.airdrop .airdrop_wrapper .section_2 .inputs .rm {
  display: block;
  width: 100%;
  margin-top: 20px;
  color: var(--theme-yellow);
}

@media (max-width: 1100px) {
  .airdrop .airdrop_wrapper .section_2 .inputs .rm {
    margin-bottom: 70px;
  }
}

.airdrop .airdrop_wrapper .section_2 .rt {
  margin-right: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 50px 20px;
  border-radius: 20px;
}

@media (max-width: 1100px) {
  .airdrop .airdrop_wrapper .section_2 .rt {
    margin-bottom: 50px;
  }
}

.airdrop .airdrop_wrapper .section_2 .rt .data {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  width: fit-content;
}

.airdrop .airdrop_wrapper .section_2 .rt .data .value {
  display: inline-block;
  font-weight: bold;
  color: var(--theme-yellow);
  margin-left: 5px;
}

@media (max-width: 1000px) {
  .airdrop .airdrop_wrapper .section_2 .rt .data {
    font-size: 12px;
  }
}

.airdrop .airdrop_wrapper .section_2 .rt .btns {
  width: 100%;
  display: flex;
  justify-content: center;
}

.airdrop .airdrop_wrapper .section_2 .rt .btns div {
  padding: 10px 20px;
  background-color: var(--theme-yellow);
  color: #fff;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .airdrop .airdrop_wrapper .section_2 .rt .btns div {
    padding: 7px 14px;
    font-size: 13px;
  }
}

.airdrop .airdrop_wrapper .section_2 .rt .btns .lb {
  margin-right: 20px;
}
