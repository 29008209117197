.submit_page {
  width: 100%;
}

.submit_page main.page_main {
  padding: 30px;
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: var(--theme-dark-blue);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 1000px) {
  .submit_page main.page_main {
    padding: 30px 5px;
  }
}

.submit_page main.page_main .form_buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.submit_page main.page_main .form_buttons button {
  width: fit-content !important;
  border: none;
  padding: 10px 40px;
  color: white;
  border-radius: 10px;
  background-color: var(--theme-yellow);
  cursor: pointer;
}

.submit_page main.page_main .form_buttons button:hover {
  background-color: #d89d08;
}

.submit_page main.page_main .items_wrapper {
  margin-bottom: 40px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 1000px) {
  .submit_page main.page_main .items_wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
  }
}

.submit_page main.page_main .items_wrapper .form_section {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 1000px) {
  .submit_page main.page_main .items_wrapper .form_section {
    width: 100%;
  }
}

.submit_page main.page_main .items_wrapper .form_section .wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.submit_page main.page_main .items_wrapper .form_section .wrapper .row {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
}

.submit_page main.page_main .items_wrapper .form_section .wrapper .row span {
  width: 50%;
  display: block;
}

.submit_page main.page_main .items_wrapper .form_section .wrapper .row span.property {
  text-align: left;
  color: white;
  word-wrap: break-word;
}

@media (max-width: 1000px) {
  .submit_page main.page_main .items_wrapper .form_section .wrapper .row span.property {
    font-size: 14px;
  }
}

.submit_page main.page_main .items_wrapper .form_section .wrapper .row span.value {
  text-align: right;
  color: var(--theme-yellow);
  word-wrap: break-word;
}

@media (max-width: 1000px) {
  .submit_page main.page_main .items_wrapper .form_section .wrapper .row span.value {
    font-size: 14px;
  }
}

.submit_page main.page_main .items_wrapper .form_section .wrapper .rule,
.submit_page main.page_main .items_wrapper .form_section .wrapper .rule_mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  margin-bottom: 15px;
}

.submit_page main.page_main .items_wrapper .form_section .wrapper .rule .ruler,
.submit_page main.page_main .items_wrapper .form_section .wrapper .rule_mobile .ruler {
  width: 100%;
  height: 2px;
  border: 1px solid #5e5e5e;
  background-color: #5e5e5e;
}

.submit_page main.page_main .items_wrapper .form_section .wrapper .rule .ring,
.submit_page main.page_main .items_wrapper .form_section .wrapper .rule_mobile .ring {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #70cce8;
  position: absolute;
}

@media (min-width: 1000px) {
  .submit_page main.page_main .items_wrapper .form_section .wrapper .rule_mobile {
    display: none;
  }
}
