.footer {
  width: 100%;
  background-color: var(--theme-yellow);
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.footer p {
  display: block;
  width: 100%;
  margin-top: 25px;
  text-align: center;
  padding: 0px 30px;
  margin-bottom: 20px;
}

.footer .social {
  width: 100%;
  margin-bottom: 20px;
}

.footer .social ul {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

.footer .social ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 50px;
  cursor: pointer;
}

.footer .social ul li .ic {
  margin-right: 5px;
}

.footer .social ul li .ic img {
  width: 25px;
  height: 25px;
}

@media (max-width: 1000px) {
  .footer .social ul {
    display: none;
  }
}
