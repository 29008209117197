.page_2 {
  width: 100%;
}

.page_2 main.page_main {
  padding: 30px;
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: var(--theme-dark-blue);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.page_2 main.page_main .form_buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.page_2 main.page_main .form_buttons button {
  width: fit-content !important;
  border: none;
  padding: 10px 40px;
  color: white;
  border-radius: 10px;
  background-color: var(--theme-yellow);
  cursor: pointer;
}

.page_2 main.page_main .form_buttons button:hover {
  background-color: #d89d08;
}

.page_2 main.page_main .items_wrapper {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 1000px) {
  .page_2 main.page_main .items_wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.page_2 main.page_main .items_wrapper .form_section {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 1000px) {
  .page_2 main.page_main .items_wrapper .form_section {
    width: 100%;
  }
}

.page_2 main.page_main .items_wrapper .form_section .vesting {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.page_2 main.page_main .items_wrapper .form_section .vesting input {
  margin-left: -5px;
  margin-right: 5px;
  width: 30px;
  cursor: pointer;
  outline: none;
}

.page_2 main.page_main .items_wrapper .form_section .vesting span {
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.page_2 main.page_main .items_wrapper .form_section span.required_label {
  display: block;
  width: 100%;
  text-align: left;
  color: var(--theme-yellow);
  font-size: 17px;
  margin-bottom: 30px;
}

.page_2 main.page_main .items_wrapper .form_section span.required_label_white {
  display: block;
  width: 100%;
  text-align: left;
  color: white;
  font-size: 17px;
  margin-bottom: 10px;
}

.page_2 main.page_main .items_wrapper .form_section span.required_label_white span {
  color: var(--theme-yellow);
  font-size: 20px;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_2 {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_label {
  margin-bottom: 10px;
  width: 100%;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_label span.mt {
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_label span.ast {
  font-weight: bold;
  color: var(--theme-yellow);
  font-size: 18px;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_input {
  width: 100%;
  margin-bottom: 10px;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_input input[type="text"],
.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_input [type="number"],
.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_input [type="datetime-local"],
.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_input select {
  border: none;
  background-color: white;
  width: 100%;
  height: 35px;
  padding-left: 10px;
  color: var(--theme-dark-blue);
  outline: none;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_input input[type="text"]::placeholder,
.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_input [type="number"]::placeholder,
.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_input [type="datetime-local"]::placeholder,
.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_input select::placeholder {
  color: #6b6b6b;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_2 .group_2_input select {
  cursor: pointer;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_2 span.group_2_note {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 17px;
  color: var(--theme-yellow);
}

.page_2 main.page_main .items_wrapper .form_section .input_group_2 span.group_2_note_bold {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 17px;
  color: white;
  font-weight: bold;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_3 .group_3_label {
  width: 100%;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_3 .group_3_label span.mt {
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_3 .group_3_label .whitelist_select {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_3 .group_3_label .whitelist_select .group {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  margin-right: 50px;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_3 .group_3_label .whitelist_select .group .outer {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_3 .group_3_label .whitelist_select .group .outer .inner {
  width: 8px;
  height: 8px;
  background-color: var(--theme-yellow);
  border-radius: 50%;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_3 .group_3_label .whitelist_select .group .group_text {
  color: white;
}

.page_2 main.page_main .items_wrapper .form_section .input_group_3 .group_3_label span.required_label {
  display: block;
  width: 100%;
  text-align: left;
  color: var(--theme-yellow);
  font-size: 17px;
  margin-bottom: 0px;
}

.react_link {
  width: fit-content;
}
