.create_lock {
  width: 100%;
  background-color: var(--theme-dark-blue);
}

.create_lock .lock_form {
  padding-top: 45px;
  margin: 0 auto;
  min-height: calc(100vh - 80px);
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.create_lock .lock_form input {
  width: 100%;
  background-color: var(--theme-dark-blue);
  border-radius: 17px;
  border: 2px solid var(--theme-yellow);
  height: 60px;
  padding-left: 20px;
  color: white;
  outline: none;
  margin-bottom: 50px;
}

.create_lock .lock_form input::placeholder {
  color: white;
}

.create_lock .lock_form .input_group {
  width: 100%;
  position: relative;
  margin-bottom: 50px;
}

.create_lock .lock_form .input_group span {
  position: absolute;
  display: inline-block;
  padding: 7px 15px;
  background-color: white;
  top: -22px;
  left: 20px;
  border-radius: 10px;
}

.create_lock .lock_form .input_group input {
  margin-bottom: 0;
}

.create_lock .lock_form .note {
  width: 100%;
  padding: 20px 15px;
  background-color: var(--theme-yellow);
  border-radius: 10px;
  font-size: 14px;
}

.create_lock .lock_form .note span {
  display: inline-block;
  margin-bottom: 5px;
}

.create_lock .lock_form .note .adr {
  color: white;
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
}

.create_lock .lock_form .note img {
  width: 12px;
  margin-right: 10px;
  cursor: pointer;
}

.create_lock .lock_form .submit {
  padding: 10px 45px;
  background-color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin-top: 40px;
  border-radius: 7px;
}
