.create_token {
  width: 100%;
}

.create_token main.page_wrapper {
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: var(--theme-dark-blue);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 80px;
}

.create_token main.page_wrapper .fill_form {
  width: 90%;
  max-width: 500px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}

.create_token main.page_wrapper .fill_form span.required_label {
  display: block;
  width: 100%;
  text-align: left;
  color: var(--theme-yellow);
  font-size: 17px;
  margin-bottom: 30px;
}

.create_token main.page_wrapper .fill_form .input_group_1 {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.create_token main.page_wrapper .fill_form .input_group_1 .group_1_label {
  margin-bottom: 10px;
  width: 100%;
}

.create_token main.page_wrapper .fill_form .input_group_1 .group_1_label span.mt {
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
}

.create_token main.page_wrapper .fill_form .input_group_1 .group_1_label span.ast {
  font-weight: bold;
  color: var(--theme-yellow);
  font-size: 18px;
}

.create_token main.page_wrapper .fill_form .input_group_1 .group_1_input {
  width: 100%;
  margin-bottom: 10px;
}

.create_token main.page_wrapper .fill_form .input_group_1 .group_1_input select,
.create_token main.page_wrapper .fill_form .input_group_1 .group_1_input input[type="text"],
.create_token main.page_wrapper .fill_form .input_group_1 .group_1_input [type="number"],
.create_token main.page_wrapper .fill_form .input_group_1 .group_1_input [type="date"] {
  border: none;
  background-color: white;
  width: 100%;
  height: 35px;
  padding-left: 10px;
  color: var(--theme-dark-blue);
  outline: none;
}

.create_token main.page_wrapper .fill_form .input_group_1 span.group_1_note {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  color: var(--theme-yellow);
}

.create_token main.page_wrapper .fill_form .form_buttons {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create_token main.page_wrapper .fill_form .form_buttons button {
  width: fit-content !important;
  border: none;
  padding: 10px 40px;
  color: black;
  border-radius: 10px;
  background-color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.create_token main.page_wrapper .fill_form .form_buttons button:hover {
  background-color: #d5d5d5;
}

.create_token main.page_wrapper .fill_form span.created {
  display: block;
  width: 100%;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-bottom: 35px;
  font-size: 20px;
}

.create_token main.page_wrapper .fill_form .details_table {
  padding-top: 20px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
}

.create_token main.page_wrapper .fill_form .details_table .table_row {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.create_token main.page_wrapper .fill_form .details_table .table_row span {
  display: block;
  width: 50%;
  text-align: left;
  word-wrap: break-word;
}

.create_token main.page_wrapper .fill_form .address {
  display: block;
  width: 100%;
  text-align: center;
  color: var(--theme-yellow);
  margin-bottom: 30px;
}

.create_token main.page_wrapper .fill_form .three_btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.create_token main.page_wrapper .fill_form .three_btns div {
  width: 300px;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
  margin-bottom: 15px;
}

.create_token main.page_wrapper .fill_form .three_btns .yl {
  background-color: var(--theme-yellow);
}
